<template>
  <div class="col-lg-6">
    <div class="faq-accordion">
      <ul class="accordion">
        <li class="accordion-item" v-for="news in filmNews_data" :key="news.id">
          <a class="accordion-title" href="javascript:void(0)">
            <i class="fa fa-plus"></i>
            {{news[0]}}
          </a>

          <div class="accordion-content" v-if="news[1]">
            <ul>
              <li v-for="link in news[1]" :key="link.id">
                <a :href="link.url" target="_blank" rel="noreferrer">
                  {{link.title}}
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import $ from "jquery";

  export default {
    props: ['filmNews'],
    data() {
      return {
        filmNews_data: []
      }
    },
    name: "film-accordion",
    methods: {
      groupByKey(array, key) {
        return array
            .reduce((hash, obj) => {
              if(obj[key] === undefined) return hash;
              return Object.assign(hash, { [obj[key]]:( hash[obj[key]] || [] ).concat(obj)})
            }, {})
      }
    },
    async mounted() {

      this.filmNews_data = await this.groupByKey(this.filmNews, 'year');

      let arr = [];
      for (const value of Object.entries(this.filmNews_data)) {
        arr.push(value)
      }
      this.filmNews_data = arr.reverse();


      $(function() {
        $('.accordion').find('.accordion-title').on('click', function(){
          // Adds Active Class
          $(this).toggleClass('active');
          // Expand or Collapse This Panel
          $(this).next().slideToggle('fast');
          // Hide The Other Panels
          $('.accordion-content').not($(this).next()).slideUp('fast');
          // Removes Active Class From Other Titles
          $('.accordion-title').not($(this)).removeClass('active');
        });
      });
    }
  }
</script>

<style scoped lang="scss">
  .accordion {
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        position: relative;
        padding-bottom: 10px;
        margin-bottom: 10px;
        color: #666666;
        padding-left: 20px;
        font-size: 15px;
        font-weight: 600;
        border-bottom: 1px solid #efefef;

        &:before {
          background: #31bfd7;
          position: absolute;
          height: 10px;
          width: 10px;
          content: '';
          left: 0;
          top: 6px;
          -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
          clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        }

        &:last-child {
          margin-bottom: 0;
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }
  }
</style>
