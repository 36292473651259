<template>
  <div id="films-news"  data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
    <div class="page-title-area films-bg-img bg-11" style="background-color: #887df4;">
      <div class="container">
        <div class="page-title-content">
          <h2>PeaceJam Films News</h2>
          <ul>
            <li class="active">Nobel Legacy Film Awards and Media</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="loading" style="height: 500px" v-if="resentFilms_data === undefined && !resentFilms_error">
      <Loading />
    </div>
    <section class="faq-contact-area ptb-100" v-if="resentFilms_data" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
      <div class="container" v-if="resentFilms_data.data.length">
        <div class="row">
          <div class="col-lg-12">
            <div class="contact-wrap">
              <div class="contact-form">
                <div class="section-title">
                  <h2>most recent films</h2>
                </div>

                <div class="row justify-content-center">
                  <div class="col-lg-4 col-md-6 mb-4" v-for="film in resentFilms_data.data" :key="film.id">
                    <div class="films-news-card">
                      <a :href="film.video_url" target="_blank" rel="noreferrer" class="film-img d-block">
                        <DefaultImage :id="film.video_image" :height="'100%'" :width="'100%'" :default-img="'film-default.jpg'" />
                        <div class="play-btn">
                          <img src="../assets/img/about/play.png" alt="">
                        </div>
                      </a>
                      <div class="film-user">
                        <div class="image">
                          <DefaultImage :id="film.image" :height="'100%'" :width="'100%'" />
                        </div>
                        <div class="description">
                          <router-link :to="'/film/'+film.slug" tag="h6">{{film.title}}</router-link>
                          <p>{{film.subtitle}}</p>
                        </div>
                        <div class="buttons">
                          <router-link :to="'/film/'+film.slug">
                            <i class="flaticon-curve-arrow-1"></i>
                            More info
                          </router-link>
                          <a :href="film.video_url" target="_blank" rel="noreferrer">
                            <i class="flaticon-vision"></i>
                            Watch film
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Start Faq Area -->
    <div class="faq-section ptb-100">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6" v-if="filmNews_data === undefined && !filmNews_error">
            <div class="loading">
              <Loading />
            </div>
          </div>
          <FilmAccordion v-if="filmNews_data" :filmNews="filmNews_data.data" />

          <div class="col-lg-6">
            <div class="faq-image">
              <img src="../assets/img/map-bg.svg" width="150" height="150" alt="image">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import api from "../services/api";
  import DefaultImage from "../components/DefaultImage";
  import Loading from "../components/Loading";
  import useSWRV from "swrv";
  import FilmAccordion from "../components/films/FilmAccordion";

  export default {
    name: "filmsNews",
    setup() {
      let {
        data: filmNews_data,
        error: filmNews_error,
        isValidating: filmNews_isValidating
      } = useSWRV(() => `/items/film_news?status=published&sort=-year`, api.fetcher);

      let {
        data: resentFilms_data,
        error: resentFilms_error,
        isValidating: resentFilms_isValidating
      } = useSWRV(() => `items/films?status=published&sort=-published&limit=3`, api.fetcher);

      return {
        filmNews_data,
        filmNews_error,
        filmNews_isValidating,

        resentFilms_data,
        resentFilms_error,
        resentFilms_isValidating
      }
    },
    components: {FilmAccordion, Loading, DefaultImage},

    async mounted() {
      window.scrollTo(0, 0);
    }
  }
</script>

<style scoped lang="scss">
  .films-bg-img {
    background-image: url("../assets/img/dawn,_Ivan,_Dalai_Lama_and_Zabe_.jpg");
    background-size: cover;
    background-position: 0 30%;

    >div {
      position: relative;
      z-index: 1;
    }

    &:after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #00000057;
    }
  }
  .faq-section {
    background-image: url("../assets/img/jobs-bg.jpg");
  }
  .films-news-card {
    width: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.07);
    height: 100%;
    position: relative;
    margin-bottom: 20px;

    .film-img {
      height: 200px;
      position: relative;
      cursor: pointer;

      .play-btn {
        position: absolute;
        z-index: 2;
        top: 15px;
        left: 15px;
        cursor: pointer;
        transition: 0.5s;
        opacity: 0;

        img {
          width: 40px;
          height: 40px;
          filter: invert(0.7);
          transition: filter 0.3s, 0.3s;
        }
      }

      &:hover .play-btn {
        opacity: 1;
      }
    }

    .film-user {
      padding: 0 25px 25px;

      h6 {
        font-size: 18px;
        line-height: 1.5;
        margin-bottom: 10px;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        font-weight: bold;

        &:hover {
          color: #32c0d6;
          cursor: pointer;
        }
      }

      .buttons {
        position: absolute;
        bottom: 0;

        a {
          font-size: 15px;
          display: inline-block;
          color: #666666;
          font-weight: 500;
          text-transform: uppercase;
          margin-bottom: 25px;
          -webkit-transition: 0.5s;
          transition: 0.5s;

          &:last-child {
            margin-left: 20px;
            color: #FC4040;
          }

          &:hover {
            color: #32c0d6;
          }
        }
      }

      .description {
        margin-bottom: 40px;
      }

      .image {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto;
        border: 4px solid #fff;
        margin-top: -75px;
        margin-bottom: 20px;
        position: relative;
      }
    }
  }
</style>
